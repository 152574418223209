.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-title {
  font-size: clamp(2.1rem, 5vw, 3.5rem);
  font-weight: bold;
}

.global-navbar {
  z-index: 1000; /* A value higher than the z-index used in the Home component */
  position: relative; /* or 'fixed', depending on your layout */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767.98px) { /* Adjust the pixel value based on the md breakpoint */
  .border-md-none {
    border-right: none !important;
  }
}

